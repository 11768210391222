var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticStyle: {
      "background-color": "#EFEFEF"
    }
  }, [_c('back-off', {
    attrs: {
      "url": "/order/list",
      "title": "Order Detail"
    }
  }), _c('div', {
    staticClass: "order-detail-box",
    staticStyle: {
      "background-color": "#EFEFEF"
    }
  }, [_vm.addressInfo.consignee !== undefined ? _c('div', {
    staticStyle: {
      "border-radius": "1.56vw",
      "overflow": "hidden",
      "margin-left": "2.56vw",
      "margin-top": "2vw",
      "width": "94.87vw",
      "height": "32.05vw",
      "background-color": "#FFFFFF"
    }
  }, [_c('div', {
    staticStyle: {
      "width": "90.87vw",
      "height": "10.68vw",
      "margin-left": "4vw",
      "margin-top": "2vw",
      "align-items": "center",
      "display": "flex"
    }
  }, [_vm._v(" " + _vm._s(_vm.addressInfo.consignee) + "   " + _vm._s(_vm.addressInfo.phone) + " ")]), _c('div', {
    staticStyle: {
      "width": "90.87vw",
      "height": "6.68vw",
      "margin-left": "4vw",
      "align-items": "center",
      "display": "flex"
    }
  }, [_vm._v(" " + _vm._s(_vm.addressInfo.addressLine2) + "   " + _vm._s(_vm.addressInfo.addressLine1) + " ")]), _c('div', {
    staticStyle: {
      "width": "90.87vw",
      "height": "10.68vw",
      "margin-left": "4vw",
      "margin-bottom": "2vw",
      "align-items": "center",
      "display": "flex"
    }
  }, [_vm._v(" " + _vm._s(_vm.addressInfo.city) + "   " + _vm._s(_vm.addressInfo.state) + "   " + _vm._s(_vm.addressInfo.postalCode) + " ")])]) : _vm._e(), _c('div', {
    staticStyle: {
      "margin-left": "2.56vw",
      "margin-top": "2vw",
      "background-color": "#FFE9EC",
      "width": "94.88vw",
      "border-radius": "1.56vw"
    }
  }, [_c('div', {
    staticStyle: {
      "background-color": "#FFFFFF",
      "width": "94.88vw",
      "height": "32.05vw",
      "border-radius": "1.56vw"
    }
  }, [_c('van-image', {
    staticStyle: {
      "margin-top": "3.2vw",
      "margin-left": "3.2vw",
      "display": "inline-block",
      "width": "25.64vw",
      "height": "25.64vw",
      "border-radius": "1.56vw",
      "overflow": "hidden"
    },
    attrs: {
      "src": _vm.orderDetail.products[0].coverImg
    }
  }), _c('div', {
    staticStyle: {
      "margin-top": "3.2vw",
      "margin-left": "3.2vw",
      "display": "inline-block",
      "width": "45.64vw",
      "height": "25.64vw",
      "overflow": "hidden"
    }
  }, [_c('div', {
    staticStyle: {
      "font-size": "3.08vw",
      "font-weight": "bold",
      "display": "flex",
      "align-items": "center"
    }
  }, [_vm._v(_vm._s(_vm.orderDetail.products[0].productName))]), _c('div', {
    staticStyle: {
      "height": "6.41vw",
      "font-size": "3.08vw",
      "color": "#BBBBBB",
      "display": "flex",
      "align-items": "center"
    }
  }, [_vm._v(_vm._s(_vm.orderDetail.products[0].skuName))]), _c('div', {
    staticStyle: {
      "margin-top": "8vw",
      "height": "6.41vw",
      "font-size": "3.08vw"
    }
  }, [_c('span', [_vm._v(" " + _vm._s(_vm.orderDetail.currencyCode) + _vm._s(_vm.orderDetail.products[0].salePrice / 100) + " ")])])]), _c('div', {
    staticStyle: {
      "margin-top": "3.2vw",
      "display": "inline-block",
      "width": "15.9vw",
      "height": "25.64vw",
      "border-radius": "1.56vw",
      "overflow": "hidden"
    }
  }, [_c('van-button', {
    staticStyle: {
      "font-size": "3.08vw",
      "border": "solid 0.3vw #BBBBBB",
      "width": "15.9vw",
      "height": "5.9vw",
      "border-radius": "1.56vw"
    },
    on: {
      "click": [function ($event) {
        $event.stopPropagation();
      }, function ($event) {
        return _vm.goToPreview(_vm.orderDetail.products[0].id, _vm.orderDetail.products[0].orderId, _vm.orderDetail.products[0].baseInfo.type);
      }]
    }
  }, [_vm._v(" Preview ")]), _c('span', {
    staticStyle: {
      "margin-top": "13.5vw",
      "justify-content": "center",
      "display": "flex",
      "font-size": "3.59vw"
    }
  }, [_vm._v(" x" + _vm._s(_vm.orderDetail.products[0].count) + " ")])], 1)], 1), _vm.orderDetail.shippingOrders.length > 0 ? _c('div', {
    staticStyle: {
      "margin-top": "1vw",
      "width": "94.88vw",
      "height": "8vw",
      "display": "flex",
      "align-items": "center"
    }
  }, [_c('span', {
    staticStyle: {
      "color": "#BD3124",
      "font-size": "3.59vw",
      "font-weight": "bold",
      "margin-left": "4vw",
      "width": "16.88vw",
      "height": "8vw",
      "display": "flex",
      "align-items": "center"
    }
  }, [_vm._v("Shipping")]), _c('span', {
    staticStyle: {
      "font-size": "3.59vw",
      "margin-left": "0vw",
      "width": "40.88vw",
      "height": "8vw",
      "display": "flex",
      "align-items": "center"
    }
  }, [_vm._v(_vm._s(_vm.orderDetail.shippingOrders[0].expressNum))]), _c('van-button', {
    staticClass: "copy-button",
    staticStyle: {
      "border": "solid 0.3vw #BBBBBB",
      "background-color": "#FFE9EC",
      "color": "#BD3124",
      "font-size": "3.59vw",
      "margin-left": "14vw",
      "width": "14.1vw",
      "height": "6.41vw",
      "display": "flex",
      "justify-content": "center",
      "align-items": "center",
      "border-radius": "1.56vw"
    },
    on: {
      "click": function ($event) {
        return _vm.copyButton(1);
      }
    }
  }, [_vm._v("copy")])], 1) : _vm._e(), _vm.orderDetail.shippingOrders.length > 0 && _vm.orderDetail.shippingOrders[0].trace.length > 0 ? _c('div', {
    staticStyle: {
      "margin-top": "1vw",
      "margin-left": "3.85vw",
      "overflow": "hidden",
      "width": "87.18vw",
      "background-color": "#FFFFFF",
      "border": "solid 0.5vw #BBBBBB",
      "border-radius": "1.56vw"
    },
    on: {
      "click": function ($event) {
        return _vm.showShippingDetail();
      }
    }
  }, [_vm.orderDetail.shippingOrders[0].trace.length > 0 ? _c('div', {
    staticStyle: {
      "display": "flex",
      "flex-wrap": "nowrap",
      "width": "87.18vw",
      "height": "8.72vw"
    }
  }, [_c('span', {
    staticStyle: {
      "display": "flex",
      "align-items": "center",
      "font-size": "3.075vw",
      "width": "66.41vw",
      "height": "8.72vw"
    }
  }, [_vm._v(_vm._s(_vm.orderDetail.shippingOrders[0].trace[0].time))]), _c('span', {
    staticStyle: {
      "display": "flex",
      "align-items": "center",
      "justify-content": "center",
      "font-size": "3.075vw",
      "width": "20.77vw",
      "height": "8.72vw"
    }
  }, [_vm._v(_vm._s(_vm.orderDetail.shippingOrders[0].trace[0].status))])]) : _vm._e(), _vm.orderDetail.shippingOrders[0].trace.length > 0 ? _c('div', {
    staticStyle: {
      "height": "0.3vw",
      "background-color": "#BBBBBB",
      "width": "77.18vw",
      "margin-left": "5vw"
    }
  }) : _vm._e(), _vm.orderDetail.shippingOrders[0].trace.length > 0 ? _c('div', [_c('div', {
    staticStyle: {
      "width": "77.95vw",
      "height": "1.5vw"
    }
  }), _c('div', {
    staticStyle: {
      "width": "77.95vw",
      "font-size": "3.077vw",
      "margin-left": "0.7vw",
      "display": "inline-block"
    }
  }, [_vm._v(_vm._s(_vm.orderDetail.shippingOrders[0].trace[0].context))]), _c('div', {
    staticStyle: {
      "width": "7vw",
      "height": "7vw",
      "display": "inline-block",
      "position": "relative",
      "right": "-2vw",
      "top": "-5vw",
      "font-size": "6.15vw"
    }
  }, [_vm._v(">")]), _c('div', {
    staticStyle: {
      "width": "77.95vw",
      "height": "1.5vw"
    }
  })]) : _vm._e()]) : _vm._e()]), _c('div', {
    staticStyle: {
      "font-size": "3.59vw",
      "color": "#606060",
      "border-radius": "1.56vw",
      "overflow": "hidden",
      "margin-left": "2.56vw",
      "margin-top": "2vw",
      "width": "94.87vw",
      "height": "32.31vw",
      "background-color": "#FFFFFF"
    }
  }, [_c('div', {
    staticStyle: {
      "height": "10.58vw",
      "width": "94.87vw",
      "display": "flex",
      "justify-content": "space-between",
      "align-items": "center"
    }
  }, [_c('span', {
    staticStyle: {
      "height": "10.58vw",
      "width": "44.87vw",
      "display": "flex",
      "margin-left": "4vw",
      "align-items": "center",
      "justify-content": "flex-start"
    }
  }, [_vm._v(" Shipping Fee")]), _c('span', {
    staticStyle: {
      "height": "10.58vw",
      "width": "30vw",
      "margin-right": "10vw",
      "display": "flex",
      "align-items": "center",
      "justify-content": "flex-end"
    }
  }, [_vm._v(_vm._s(_vm.orderDetail.currencyCode) + " " + _vm._s((_vm.orderDetail.postFee / 100).toFixed(2)))])]), _c('div', {
    staticStyle: {
      "height": "10.58vw",
      "width": "94.87vw",
      "display": "flex",
      "justify-content": "space-between",
      "align-items": "center"
    }
  }, [_c('span', {
    staticStyle: {
      "height": "10.58vw",
      "width": "44.87vw",
      "display": "flex",
      "margin-left": "4vw",
      "align-items": "center",
      "justify-content": "flex-start"
    }
  }, [_vm._v("Sub Total")]), _c('span', {
    staticStyle: {
      "height": "10.58vw",
      "width": "30vw",
      "margin-right": "10vw",
      "display": "flex",
      "align-items": "center",
      "justify-content": "flex-end"
    }
  }, [_vm._v(_vm._s(_vm.orderDetail.currencyCode) + " " + _vm._s((_vm.orderDetail.realAmount / 100).toFixed(2)))])]), _c('div', {
    staticStyle: {
      "height": "10.58vw",
      "width": "94.87vw",
      "display": "flex",
      "justify-content": "space-between",
      "align-items": "center"
    }
  }, [_c('span', {
    staticStyle: {
      "height": "10.58vw",
      "width": "44.87vw",
      "display": "flex",
      "margin-left": "4vw",
      "align-items": "center",
      "justify-content": "flex-start"
    }
  }, [_vm._v("Amount To Pay")]), _c('span', {
    staticStyle: {
      "height": "10.58vw",
      "width": "30vw",
      "margin-right": "10vw",
      "display": "flex",
      "align-items": "center",
      "justify-content": "flex-end"
    }
  }, [_vm._v(_vm._s(_vm.orderDetail.currencyCode) + " " + _vm._s((_vm.orderDetail.realAmount / 100).toFixed(2)))])])]), _c('div', {
    staticStyle: {
      "font-size": "3.59vw",
      "color": "#606060",
      "border-radius": "1.56vw",
      "overflow": "hidden",
      "margin-left": "2.56vw",
      "margin-top": "2vw",
      "width": "94.87vw",
      "background-color": "#FFFFFF"
    }
  }, [_c('div', {
    staticStyle: {
      "height": "10.58vw",
      "width": "94.87vw",
      "display": "flex",
      "align-items": "center"
    }
  }, [_c('span', {
    staticStyle: {
      "height": "10.58vw",
      "width": "30.87vw",
      "display": "flex",
      "margin-left": "4vw",
      "align-items": "center",
      "justify-content": "flex-start"
    }
  }, [_vm._v(" OrderSn")]), _c('span', {
    staticStyle: {
      "height": "10.58vw",
      "width": "45vw",
      "margin-left": "2vw",
      "display": "flex",
      "align-items": "center",
      "justify-content": "flex-start"
    }
  }, [_vm._v(_vm._s(_vm.orderDetail.orderNum))]), _c('van-button', {
    staticClass: "copy-button",
    staticStyle: {
      "border": "solid 0.3vw #BBBBBB",
      "background-color": "#FFFFFF",
      "color": "#BD3124",
      "font-size": "3.59vw",
      "margin-left": "0vw",
      "width": "12vw",
      "height": "6.41vw",
      "display": "flex",
      "justify-content": "center",
      "align-items": "center",
      "border-radius": "1.56vw"
    },
    on: {
      "click": function ($event) {
        return _vm.copyButton(2);
      }
    }
  }, [_vm._v("copy")])], 1), !_vm.orderDetail.orderBtn.canShowPay ? _c('div', {
    staticStyle: {
      "height": "10.58vw",
      "width": "94.87vw",
      "display": "flex",
      "align-items": "center"
    }
  }, [_c('span', {
    staticStyle: {
      "height": "10.58vw",
      "width": "30.87vw",
      "display": "flex",
      "margin-left": "4vw",
      "align-items": "center",
      "justify-content": "flex-start"
    }
  }, [_vm._v("PaymentChannel")]), _c('span', {
    staticStyle: {
      "height": "10.58vw",
      "width": "30vw",
      "margin-left": "2vw",
      "display": "flex",
      "align-items": "center",
      "justify-content": "flex-start"
    }
  }, [_vm._v(_vm._s(_vm.orderDetail.paymentChannelText))])]) : _vm._e(), _c('div', {
    staticStyle: {
      "height": "10.58vw",
      "width": "94.87vw",
      "display": "flex",
      "align-items": "center"
    }
  }, [_c('span', {
    staticStyle: {
      "height": "10.58vw",
      "width": "30.87vw",
      "display": "flex",
      "margin-left": "4vw",
      "align-items": "center",
      "justify-content": "flex-start"
    }
  }, [_vm._v("CreatedAt")]), _c('span', {
    staticStyle: {
      "height": "10.58vw",
      "width": "40vw",
      "margin-left": "2vw",
      "display": "flex",
      "align-items": "center",
      "justify-content": "flex-start"
    }
  }, [_vm._v(_vm._s(_vm.orderDetail.createdAtFmt))])])]), _c('br'), _c('br'), _c('div', {
    staticClass: "order-list-item-btn-box",
    staticStyle: {
      "position": "fixed",
      "bottom": "0px",
      "background-color": "#FFFFFF",
      "margin-top": "1vw",
      "width": "94.88vw",
      "height": "8.05vw",
      "border-radius": "1.56vw",
      "margin-left": "2.56vw",
      "align-items": "center"
    }
  }, [_vm.orderDetail.orderBtn.canShowPay ? _c('van-button', {
    staticStyle: {
      "background-color": "#D56460",
      "margin-left": "1vw",
      "border-radius": "1.56vw",
      "color": "#EFEFEF",
      "font-size": "3.59vw",
      "font-weight": "bold",
      "width": "26.41vw",
      "margin-top": "1vw",
      "float": "right",
      "height": "6.05vw"
    },
    attrs: {
      "size": "mini",
      "round": "",
      "plain": "",
      "type": "danger"
    },
    on: {
      "click": function ($event) {
        return _vm.onClickGoPay(_vm.orderDetail.id, _vm.orderDetail.realAmount);
      }
    }
  }, [_vm._v("Pay")]) : _vm._e(), _vm.orderDetail.orderBtn.canShowCancel ? _c('van-button', {
    staticStyle: {
      "border": "solid 0.3vw #BBBBBB",
      "border-radius": "1.56vw",
      "font-size": "3.59vw",
      "width": "26.41vw",
      "margin-top": "1vw",
      "float": "right",
      "height": "6.05vw"
    },
    attrs: {
      "size": "mini",
      "round": "",
      "plain": "",
      "type": "default"
    },
    on: {
      "click": function ($event) {
        return _vm.onClickCancel(_vm.orderDetail.id);
      }
    }
  }, [_vm._v("Cancel")]) : _vm._e(), _vm.orderDetail.orderBtn.canShowSign ? _c('van-button', {
    staticStyle: {
      "border": "solid 0.3vw #BBBBBB",
      "border-radius": "1.56vw",
      "font-size": "3.59vw",
      "width": "26.41vw",
      "margin-top": "1vw",
      "float": "right",
      "height": "6.05vw"
    },
    attrs: {
      "size": "mini",
      "round": "",
      "plain": "",
      "type": "default"
    },
    on: {
      "click": function ($event) {
        return _vm.onClickEditAddress(_vm.orderDetail.addressInfo);
      }
    }
  }, [_vm._v("confirm deliver")]) : _vm._e(), _vm.orderDetail.orderBtn.canShowChangeAddress ? _c('van-button', {
    staticStyle: {
      "border": "solid 0.3vw #BBBBBB",
      "border-radius": "1.56vw",
      "font-size": "3.59vw",
      "width": "32.41vw",
      "margin-top": "1vw",
      "float": "right",
      "height": "6.05vw"
    },
    attrs: {
      "size": "mini",
      "round": "",
      "plain": "",
      "type": "default"
    },
    on: {
      "click": function ($event) {
        return _vm.onClickEditAddress(_vm.orderDetail.addressInfo);
      }
    }
  }, [_vm._v("Change Address")]) : _vm._e()], 1), _vm.orderDetail.shippingOrders.length > 0 ? _c('van-popup', {
    staticStyle: {
      "width": "90.87vw",
      "height": "100.87vw",
      "border-radius": "1.56vw",
      "overflow": "hidden"
    },
    model: {
      value: _vm.shippingDetailShow,
      callback: function ($$v) {
        _vm.shippingDetailShow = $$v;
      },
      expression: "shippingDetailShow"
    }
  }, [_c('div', {
    staticStyle: {
      "overflow-y": "auto",
      "max-height": "100.87vw"
    }
  }, [_c('div', {
    staticStyle: {
      "display": "flex",
      "width": "90.87vw",
      "height": "10.72vw"
    }
  }, [_c('div', {
    staticStyle: {
      "font-size": "5.13vw",
      "width": "90.87vw",
      "height": "10.72vw",
      "font-weight": "bold",
      "display": "flex",
      "justify-content": "center",
      "align-items": "center"
    }
  }, [_vm._v("Shipping Detail")])]), _c('div', {
    staticStyle: {
      "height": "0.3vw",
      "background-color": "#BBBBBB",
      "width": "80.87vw",
      "margin-left": "5vw"
    }
  }), _vm._l(_vm.orderDetail.shippingOrders[0].trace, function (item, index) {
    return _c('div', {
      key: index,
      staticStyle: {
        "position": "relative",
        "margin-top": "1vw",
        "margin-bottom": "1vw",
        "width": "90.87vw",
        "background-color": "#FFFFFF"
      }
    }, [index > 0 && index < _vm.orderDetail.shippingOrders[0].trace.length - 1 ? _c('div', {
      staticStyle: {
        "width": "0.3vw",
        "background-color": "#BBBBBB",
        "top": "-1vw",
        "bottom": "-1vw",
        "margin-left": "3.9vw",
        "position": "absolute"
      }
    }) : _vm._e(), index == 0 ? _c('div', {
      staticStyle: {
        "width": "0.3vw",
        "background-color": "#BBBBBB",
        "top": "5vw",
        "bottom": "-1vw",
        "margin-left": "3.9vw",
        "position": "absolute"
      }
    }) : _vm._e(), index == _vm.orderDetail.shippingOrders[0].trace.length - 1 ? _c('div', {
      staticStyle: {
        "width": "0.3vw",
        "background-color": "#BBBBBB",
        "top": "-1vw",
        "bottom": "5vw",
        "margin-left": "3.9vw",
        "position": "absolute"
      }
    }) : _vm._e(), _c('div', {
      staticStyle: {
        "width": "8vw",
        "display": "flex",
        "align-items": "center",
        "justify-content": "center",
        "position": "absolute",
        "top": "0px",
        "bottom": "0px"
      }
    }, [index > 0 ? _c('van-icon', {
      staticStyle: {
        "background-color": "white"
      },
      attrs: {
        "color": "green",
        "name": "passed"
      }
    }) : _vm._e(), index == 0 ? _c('van-icon', {
      staticStyle: {
        "background-color": "white"
      },
      attrs: {
        "color": "#D56460",
        "name": "exchange"
      }
    }) : _vm._e()], 1), _c('div', {
      staticStyle: {
        "display": "inline-block",
        "margin-top": "1vw",
        "margin-bottom": "1vw",
        "margin-left": "8vw",
        "overflow": "hidden",
        "width": "75.18vw",
        "background-color": "#FFFFFF",
        "border": "solid 0.5vw #BBBBBB",
        "border-radius": "1.56vw"
      }
    }, [index > 0 ? _c('div', {
      staticStyle: {
        "flex-wrap": "nowrap",
        "width": "80.18vw",
        "height": "8.72vw"
      }
    }, [_c('span', {
      staticStyle: {
        "font-size": "2vw",
        "display": "flex",
        "align-items": "center",
        "width": "65.41vw",
        "height": "8.72vw"
      }
    }, [_vm._v(_vm._s(item.time))])]) : _vm._e(), index < 1 ? _c('div', {
      staticStyle: {
        "flex-wrap": "nowrap",
        "width": "80.18vw",
        "height": "13.72vw"
      }
    }, [_c('span', {
      staticStyle: {
        "margin-top": "2vw",
        "font-size": "2vw",
        "display": "flex",
        "align-items": "center",
        "width": "65.41vw",
        "height": "4.72vw"
      }
    }, [_vm._v(_vm._s(item.time))]), _c('span', {
      staticStyle: {
        "margin-left": "2vw",
        "display": "flex",
        "align-items": "center",
        "justify-content": "flex-start",
        "font-size": "2vw",
        "width": "65.77vw",
        "height": "6.72vw"
      }
    }, [_vm._v("Current Status:  " + _vm._s(item.status))])]) : _vm._e(), _c('div', {
      staticStyle: {
        "height": "0.3vw",
        "background-color": "#BBBBBB",
        "width": "65.18vw",
        "margin-left": "5vw"
      }
    }), _c('div', [_c('div', {
      staticStyle: {
        "width": "74.18vw",
        "font-size": "3.077vw",
        "display": "inline-block",
        "margin-top": "2vw",
        "padding-left": "0.7vw",
        "padding-right": "0.7vw"
      }
    }, [_vm._v(_vm._s(item.context))]), _c('div', {
      staticStyle: {
        "width": "80.18vw",
        "height": "2.56vw"
      }
    })])])]);
  })], 2)]) : _vm._e(), _vm.orderDetail.orderBtn.canShowCancel ? _c('van-popup', {
    staticStyle: {
      "width": "90.87vw",
      "height": "45.87vw",
      "border-radius": "1.56vw",
      "overflow": "hidden"
    },
    model: {
      value: _vm.cancelOrderShow,
      callback: function ($$v) {
        _vm.cancelOrderShow = $$v;
      },
      expression: "cancelOrderShow"
    }
  }, [_c('div', {
    staticStyle: {
      "margin-top": "5vw",
      "display": "flex",
      "align-items": "center",
      "justify-content": "center",
      "width": "90.87vw",
      "height": "10vw",
      "font-size": "5vw",
      "font-weight": "bold",
      "color": "#BD3124"
    }
  }, [_vm._v("The operation cannot be withdrawn,")]), _c('div', {
    staticStyle: {
      "display": "flex",
      "align-items": "center",
      "justify-content": "center",
      "width": "90.87vw",
      "height": "10vw",
      "font-size": "5vw",
      "font-weight": "bold",
      "color": "#BD3124"
    }
  }, [_vm._v("whether to continue operating ?")]), _c('div', [_c('van-button', {
    staticStyle: {
      "margin-left": "10vw",
      "background-color": "#BD3124",
      "border": "0px",
      "border-radius": "1.56vw",
      "color": "#EFEFEF",
      "font-size": "3.59vw",
      "font-weight": "bold",
      "width": "29.49vw",
      "margin-top": "5vw",
      "height": "8.46vw"
    },
    attrs: {
      "round": "",
      "plain": "",
      "type": "danger"
    },
    on: {
      "click": function ($event) {
        return _vm.confirmCancelOrder();
      }
    }
  }, [_vm._v("Yes")]), _c('van-button', {
    staticStyle: {
      "margin-left": "10vw",
      "background-color": "#8DC787",
      "border": "0px",
      "border-radius": "1.56vw",
      "color": "#EFEFEF",
      "font-size": "3.59vw",
      "font-weight": "bold",
      "width": "29.49vw",
      "margin-top": "5vw",
      "height": "8.46vw"
    },
    attrs: {
      "round": "",
      "plain": "",
      "type": "danger"
    },
    on: {
      "click": function ($event) {
        return _vm.cancelCancelOrder();
      }
    }
  }, [_vm._v("No")])], 1)]) : _vm._e(), _vm.editAddressFlag ? _c('edit-address-popup', {
    attrs: {
      "old-address": _vm.oldAddress,
      "edit-address-popup-show": _vm.editAddressFlag
    },
    on: {
      "callbackForEdit": _vm.callbackForEdit,
      "callbackForCancelEdit": _vm.callbackForCancelEdit
    }
  }) : _vm._e(), _vm.orderDetail.orderBtn.canShowPay ? _c('payment-channel-popup', {
    attrs: {
      "order-id": _vm.orderId,
      "popup": _vm.paymentShow,
      "currency-code": _vm.orderDetail.currencyCode,
      "total-fee": _vm.orderDetail.realAmount
    },
    on: {
      "callbackForClose": _vm.callbackForClose
    }
  }) : _vm._e()], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };