import "core-js/modules/es.array.push.js";
import EditAddressPopup from '../../common/EditAddressPopup.vue';
import { orderDetail, orderCancel } from '../../../api/order.js';
import BackOff from '../../common/BackOff.vue';
import PaymentChannelPopup from '../../common/PaymentChannelPopup.vue';
import Clipboard from 'clipboard';
import { Button, Icon, Popup, Toast } from 'vant';
export default {
  name: 'OrderDetail',
  components: {
    EditAddressPopup,
    BackOff,
    // OrderTrace,
    PaymentChannelPopup,
    [Toast.name]: Toast,
    [Popup.name]: Popup,
    [Button.name]: Button,
    [Icon.name]: Icon
  },
  data() {
    return {
      cancelOrderNum: '',
      cancelOrderShow: false,
      shippingExpressNum: String,
      editAddressFlag: false,
      shippingDetailShow: false,
      orderId: this.$route.query.orderId,
      // 给修改地址弹窗子组件的传值
      oldAddress: {},
      paymentShow: this.$route.query.paymentShow === true,
      orderProcessList: [],
      createTime: '',
      orderDetail: [],
      addressInfo: {}
    };
  },
  created: async function () {
    this.getOrderDetail();
  },
  mounted() {
    // 创建新的剪贴板实例
    this.clipboard = new Clipboard('.copy-button', {
      text: () => this.copyContent // 将内容复制到剪切板
    });

    // 监听复制成功事件
    Toast.setDefaultOptions({
      duration: 2000
    });
    this.clipboard.on('success', () => {
      Toast({
        message: 'Copied'
      });
    });
  },
  destroyed() {
    // 销毁剪贴板实例
    this.clipboard.destroy();
  },
  methods: {
    onClickCancel(orderId) {
      this.cancelOrderShow = true;
      this.cancelOrderNum = orderId;
    },
    confirmCancelOrder() {
      const data = {
        orderId: this.cancelOrderNum
      };
      orderCancel(data).then(res => {
        res;
        this.getOrderDetail();
        this.cancelOrderShow = false;
      });
    },
    cancelCancelOrder() {
      this.cancelOrderShow = false;
    },
    copyButton(index) {
      // eslint-disable-next-line eqeqeq
      if (index == 1) {
        this.copyContent = this.orderDetail.shippingOrders[0].expressNum;
      } else {
        this.copyContent = this.orderDetail.orderNum;
      }
      this.clipboard.onClick({
        // 触发复制操作
        target: document.querySelector('.copy-button')
      });
    },
    // 修改订单地址
    onClickEditAddress(item) {
      this.oldAddress = item;
      this.editAddressFlag = false;
      this.editAddressFlag = true;
      // this.addAddressFlag = false
    },

    // 确定修改地址回调
    callbackForEdit() {
      this.editAddressFlag = false;
      this.getOrderDetail();
    },
    // 取消修改地址回调
    callbackForCancelEdit() {
      this.editAddressFlag = false;
    },
    callbackForClose() {
      if (this.paymentShow) {
        this.paymentShow = false;
      }
    },
    showShippingDetail() {
      this.shippingDetailShow = true;
    },
    getOrderDetail() {
      const orderId = {
        orderId: this.orderId
      };
      orderDetail(orderId).then(res => {
        this.orderDetail = res.data.data;
        // this.createTime = dateFormate(orderDetail.createdAtFmt)
        this.addressInfo = this.orderDetail.addressInfo;
      });
    },
    goToPreview(orderProductId, orderId, productType) {
      const backUrl = window.location.pathname + '?' + window.location.search;
      // eslint-disable-next-line eqeqeq
      if (productType == 1) {
        this.$router.push({
          path: '/order/preview/commonCustom',
          query: {
            orderProductId: orderProductId,
            orderId: orderId,
            backUrl: backUrl
          }
        });
        // eslint-disable-next-line eqeqeq
      } else if (productType == 3) {
        this.$router.push({
          path: '/order/preview/photoBook',
          query: {
            orderProductId: orderProductId,
            orderId: orderId,
            backUrl: backUrl
          }
        });
        // eslint-disable-next-line eqeqeq
      } else if (productType == 2) {
        this.$router.push({
          path: '/order/preview/even_photos',
          query: {
            orderProductId: orderProductId,
            orderId: orderId,
            backUrl: backUrl
          }
        });
      }
    },
    onClickGoPay() {
      this.paymentShow = !this.paymentShow;
    },
    onClickSigned() {}
  }
};